import React, {
  useState, useCallback, useEffect, useRef
} from 'react';
import { Modal, Dropdown } from 'semantic-ui-react';
import { Button } from '@make.tv/lib-web-uicomponents';

import { useUser } from 'Context/user';
import { Accounts, Users } from 'Services/resources';

/**
 * Modal that allows the admin to select an account and to impersonate it
 */
export const ImpersonateModal = ({ children }) => {
  const {
    user, fetchUser, isImpersonating, impersonatedAccount
  } = useUser();

  const [isOpen, setIsOpen] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(false);

  const selected = useRef();

  const openModal = useCallback(() => setIsOpen(true), [setIsOpen]);
  const closeModal = useCallback(() => setIsOpen(false), [setIsOpen]);

  const handleImpersonate = useCallback((accountId) => {
    if (loading) {
      return;
    }

    if (!accountId || accountId === impersonatedAccount) {
      closeModal();
      return;
    }
    setLoading(accountId === 'exit' || accountId === user.account_id ? 'exit' : 'impersonate');
    // if passed account id is the same as the user's own account id, remove impersonation
    Users.impersonate(user.user_id, accountId !== 'exit' ? accountId : user.account_id)
      .catch(() => {})
      .then(fetchUser)
      .then(closeModal)
      .then(() => {
        setLoading(false);
        window.location.reload();
      });
  }, [impersonatedAccount, user, fetchUser, closeModal, loading]);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    setLoading('data');
    Accounts.listAll().catch(() => []).then(({ accounts: accs = [] }) => {
      setAccounts(accs.map((d) => ({
        key: d.account_id,
        text: `${d.account_id} : ${d.account_name}`,
        value: d.account_id,
      })));

      setLoading(false);
    });
  }, [isOpen, setAccounts]);

  return (
    <Modal
      trigger={children({ open: openModal, close: closeModal })}
      open={isOpen}
      onClose={closeModal}
      size="small"
    >
      <Modal.Header>Temporary Access Account Override</Modal.Header>
      <Modal.Content>
        <Dropdown
          placeholder="Select Account"
          fluid
          search
          selection
          loading={!accounts.length && loading === 'data'}
          options={accounts}
          defaultValue={impersonatedAccount || user.account_id}
          onChange={(ev, data) => { selected.current = data.value; }}
        />
      </Modal.Content>
      <Modal.Actions>
        {isImpersonating && (
          <Button color="secondary" onClick={() => handleImpersonate('exit')} loading={loading === 'exit'}>
            Exit Current Impersonation
          </Button>
        )}
        <Button onClick={() => handleImpersonate(selected.current)} loading={loading === 'impersonate'}>
          Impersonate
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
